<template>
  <div>
    <page-title :title="`My Profile`"></page-title>
    <div class="row">
      <div class="col-md-9">
        <div class="card">
          <div class="card-body">
            <h3>My Profile</h3>
            <br />
            <table class="table table-striped">
              <tr>
                <td>
                  <strong>Name</strong>
                </td>
                <td>
                  {{ user.name }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Email</strong>
                </td>
                <td>{{ user.email }}</td>
              </tr>
              <tr>
                <td>
                  <strong>Phone Number</strong>
                </td>
                <td>{{ user.phone_number }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <router-link :to="{ name: 'profile.security' }" class="btn btn-sm btn-secondary btn-block">Security</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters.getAuthUser;
    },
  },
};
</script>
